export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'primary',
      borderTop: '3px solid',
      borderColor: 'secondary',
      padding: ['0rem', '0rem', '0rem', '0rem']
    },
    '.containerScrolled': {
      backgroundColor: 'primary',
      borderTop: '3px solid',
      borderColor: 'secondary',
      padding: ['0rem', '0rem', '0rem', '0rem']
    },
    '.logo': {
      left: '50%',
      transform: 'translateX(-50%)',
      img: {
        maxHeight: ['80px', '', '', '160px']
      }
    },
    '.logoScrolled': {
      left: '50%',
      transform: 'translateX(-50%)',
      img: {
        maxHeight: ['80px', '', '', '80px']
      }
    },

    '.hamburger': {
      height: '50px',
      width: '50px',
      padding: '0.5rem',
      border: 'solid 1px',
      borderColor: 'white',
      backgroundColor: 'transparent',
      '> div': {
        backgroundColor: 'white'
      }
    },
    '.hamburgerOpen': {
      '> div': {
        backgroundColor: 'white'
      }
    },

    '.navItem': {
      height: 'unset',
      a: {
        color: 'white',
        fontSize: ['1.1rem', '1.4rem'],
        fontFamily: 'subheading',
        ':hover': {
          color: 'secondary'
        }
      }
    },

    '.navMenu': {
      padding: ['0rem', '0rem', '0rem', '0rem'],
      height: '100%',
      justifyContent: 'center',
      width: '100%',
      overflow: ['', '', '', 'visible']
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      backgroundSize: 'cover',
      backgroundPosition: 'left top'
    },

    '.navItemDropdown': {
      height: ['100%', '', '', 'auto']
    },

    '.navItemDropdownMenu': {
      backgroundColor: ['transparent', '', '', 'primary'],
      borderTop: ['solid 2px'],
      borderBottom: ['', '', '', 'solid 2px'],
      borderColor: 'white',
      paddingBottom: ['1.5rem', '', '', '0rem'],
      '.ctaButton': {
        color: ['light', '', '', 'dark'],
        ':hover': {
          color: ['lightgrey', '', '', 'light']
        }
      },
      '.navItem': {
        alignItems: ['flex-start', '', '', 'center']
      }
    },

    svg: {
      path: { fill: 'white' }
    },

    '.socialContainer, .phoneContainer, .addressContainer': {
      svg: {
        path: { fill: 'white' }
      }
    }
    // '.navMenuLogo': {
    //   variant: 'customVariants.borderCorners',
    //   padding: '3rem 0rem',
    //   img: {
    //     filter: 'brightness(0) invert(1)',
    //   },
    // },
  },

  footer: {
    padding: '0rem',
    maxWidth: 'unset',
    margin: '0rem',
    backgroundColor: 'black',
    '.logo': {
      filter: 'brightness(0) invert(1)',
      display: 'none'
    },

    '.multiButtonContainer': {
      display: 'none'
    },
    '.column': {
      display: 'none'
    },
    '.gonationLogo': {
      width: 'auto'
    },
    '.bottomBar': {
      padding: '1rem',
      margin: '0rem',
      border: 'none',
      paddingBottom: ['7rem', '', '6rem']
    }
  },

  ctaWidget: {
    a: {
      display: 'flex'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '4rem', '4rem', '4.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    transform: ' rotate(-2deg)',
    color: 'secondary',
    textShadow: '3px 3px 0px white'
  },
  subtitle: {
    color: 'inherit',
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem'],
    marginBottom: '1.5rem',
    border: 'none',
    fontFamily: 'subheading'
  },
  text: {
    textAlign: 'inherit',
    color: 'inherit',
    lineHeight: '1.2',
    p: {
      lineHeight: '1.5',
      color: 'inherit',
      textAlign: 'inherit',
      fontSize: 'inherit'
    },
    li: {
      margin: '0rem',
      lineHeight: '1',
      fontSize: '1.2rem'
    }
  },

  textContent: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {},
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide1: {
    padding: '0rem',
    '.title': { variant: 'customVariants.title' },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    '.linksContainer': {
      mt: '2rem',
      justifyContent: 'flex-start'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      margin: ['auto', '', '', 'auto'],
      padding: '1rem',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['2.5rem', '3rem', '4rem', '5rem', '6rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      '.ctaButton': {
        variant: 'buttons.tertiary'
      }
    }
  },

  homepageHeroShout: {
    display: 'none !important'
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      backgroundColor: 'secondary',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primary', '', '', 'primary']
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageRewards: {
    variant: 'customVariants.textContent',
    background: 'unset',
    padding: '6rem 1rem',
    '.section': {
      border: 'solid 4px',
      borderColor: 'primary',
      padding: ['1rem', '2rem', '3rem 4rem'],
      textAlign: 'center',
      justifyContent: 'flex-start'
    },
    '.text': {
      variant: 'customVariants.text',
      lineHeight: '1',
      '> p': {
        marginBottom: '0.5rem'
      },
      ul: {
        width: 'fit-content',
        margin: '0rem auto',
        display: 'flex',
        flexDirection: 'column'
      },
      li: {
        textAlign: 'left',
        fontSize: '1.2rem',
        marginBottom: '0rem'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageTagline: {
    variant: 'customVariants.textContent',
    padding: '10rem 1rem',
    color: 'white',
    '.section': {
      padding: ['1rem', '2rem', '3rem 4rem'],
      textAlign: 'center',
      width: '90%',
      maxWidth: 'unset'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3rem', '4rem', '5rem', '6rem']
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: '1.5rem'
    }
  },

  homepageBoxes: {
    backgroundColor: 'transparent',
    padding: ['0rem', '', '', '0rem'],
    '.box': {
      width: ['100%', '', '50%', '50%'],
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      position: 'relative',
      color: 'white',
      padding: 'calc(5% + 1.5rem)',
      margin: ['1rem 1rem', '', '0rem'],
      ':hover': {
        '.image': {
          filter: 'unset'
        }
      },
      '.image': {
        position: 'absolute',
        top: '0%',
        left: '0%',
        height: '100%',
        width: '100%',
        filter: 'brightness(0.6) grayscale(70%)',
        objectFit: 'cover',
        transition: 'all ease-in-out 0.5s',
        zIndex: '-3'
      },
      '.title': {
        variant: 'customVariants.title',
        transition: 'all ease-in-out 1s',
        mt: '1rem',
        marginBottom: '1rem',
        padding: '0rem',
        paddingBottom: '0.5rem',
        zIndex: 1
        // fontSize: ['1.5rem', '1.7rem', '2rem', '2.25rem', '2.5rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontWeight: '300',
        zIndex: 1
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '1rem 0rem 1rem',
        zIndex: 1,
        fontSize: ['1.1rem'],
        mb: '2rem',
        textShadow: '2px 2px 2px black'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        margin: '0rem',
        zIndex: 1,
        width: 'fit-content'
      }
    }
  },

  hours: {
    '.hours-section': {
      padding: '2rem 1rem',
      fontSize: '0.9rem'
    },
    '.label': {
      fontSize: '0.9rem',
      color: 'primary'
    },
    '.time': {
      fontSize: '0.8rem'
    },
    '.closed': {
      fontSize: '1.5rem',
      color: 'red',
      textTransform: 'uppercase'
    }
  },

  homepageFooter: {
    flexDirection: ['column', 'column', 'column', 'column'],
    alignItems: ['center', 'center', 'center', 'center'],
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    backgroundColor: 'black',
    padding: '4rem 2rem 3rem',
    '.content': {
      alignItems: ['center', 'center', 'center', 'center'],
      textAlign: 'center',
      justifyContent: 'center'
    },
    '.lazyload-wrapper': {
      width: 'unset'
    },
    '.image': {
      maxHeight: '80px',
      width: 'auto'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    background: 'unset',
    variant: 'customVariants.textContent',
    '.content': {
      color: 'white',
      backgroundColor: 'black',
      margin: ['1rem 0rem', '', '2rem'],
      border: 'solid 3px',
      borderColor: 'primary'
    },
    '.image3': {
      objectFit: 'contain',
      backgroundColor: 'black',
      position: 'relative',
      left: ['', '', '-5vw'],
      top: ['', '', '-5vw']
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(0) invert(1)'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: ['100%', '', '45%'],
      maxHeight: 'unset'
      // height: '100%',
    },

    '.eventItemContent': {
      backgroundColor: 'black',
      padding: ['1rem', '2rem', '3rem']
    },
    '.eventItemTitle': {
      variant: 'customVariants.title',
      order: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      fontSize: '1.25rem',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    },
    '.eventCTABtns': {
      a: {
        variant: 'buttons.primary'
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ["''"],
      backgroundColor: '#0000009c',
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem',
      order: 'unset'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'text',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '2px dashed',
      borderColor: 'secondary',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'text',
      fontFamily: 'body',
      '::placeholder': {
        color: 'text'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'body'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  locationMap: {
    backgroundColor: 'primary',
    '.content_container': {
      padding: '0rem'
    },
    'h3.title': {
      variant: 'customVariants.title'
    },
    '.contactDetails-container': {
      padding: '1rem 2rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      display: 'none',
      '.hoursContainer': {
        padding: ['2rem', '2rem'],
        maxWidth: '400px',
        margin: '0rem auto',
        '.dayofWeekText': {
          color: 'dark',
          fontSize: '1.25rem',
          margin: '0rem auto 0.5rem 0rem'
        }
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    },
    '.text': {
      fontSize: ['1.25rem']
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? ====  Catering page  ====
  // ? ========================

  pdfButton: {
    background: 'unset',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  }
}
