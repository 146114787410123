export default {
  heading: 'Damion',
  subheading: 'Bebas Neue',
  body: 'Orbitron',
  monospace: 'Menlo, monospace',
  display: 'salted',
  googleFonts: [
    'Roboto:100,200,300,400,500,600,700,800,900',
    // 'Hahmlet:100,200,300,400,500,600,700,800,900',
    'Bebas Neue',
    'Damion',
    'Orbitron'
  ],
  customFamilies: ['salted', 'barrel', 'Franklin Gothic Compressed'],
  customUrls: [
    'https://www.gonation.biz/fonts/salted/salted.css',
    'https://www.gonation.biz/fonts/old-barrel-regular/old-barrel-regular.css',
    'https://www.gonation.biz/fonts/franklin-gothic-compressed/franklin-gothic-compressed.css'
  ]
}
