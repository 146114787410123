export default {
  text: 'white',
  primary: '#114da3',
  secondary: '#fe0000',
  tertiary: '#1a9789',
  background: '#09293d',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
