export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    padding: '0.75rem 1.5rem',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem 2.5rem',
    ':hover': {
      borderColor: 'secondary',
      backgroundColor: 'secondary'
    }
  },
  secondary: {
    backgroundColor: '',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    padding: '1rem 2.5rem',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  },

  tertiary: {
    clipPath: 'polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%)',
    backgroundColor: 'primary',
    fontSize: ['1.5rem', '', '1.5rem'],
    textShadow: '2px 2px 0px grey',
    color: 'white',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.5rem 0.5rem',
    fontWeight: 'bold',
    ':hover': {
      opacity: '1',
      color: 'black'
    }
  }
}
